import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Image = styled(GatsbyImage)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: transform .3s;

  img {
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  background-color: #F5F5F5;
  width: 100%;
  height: 0;
  margin: 0 0 1.5rem;
  padding-bottom: 100%;
  overflow: hidden;
`;

export const Description = styled.div`
  h2 {
    margin: 0;
    font-size: 1rem;
    color: #d72226;
    text-transform: uppercase;
  }
`;

export const GatsbyLink = styled(Link)`
  position: absolute;
  display: block;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  &:hover {
    ${Image} {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }
`;
