import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { Wrapper, Image, ImageWrapper, Description, GatsbyLink } from './Product.styled';

const Product = ({ product, url }) => (
  <Wrapper>
    <ImageWrapper>
      <Image image={getImage(product.image.value)} alt={product.title.value} />
    </ImageWrapper>
    <Description>
      <h2>{product.title.value}</h2>
    </Description>
    <GatsbyLink to={url} />
  </Wrapper>
);

export default Product;
